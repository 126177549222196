import { getExploreName } from '../utils/platform';

const host = 'europeans-abroad.com';

export const devConfig = {
	sdkKey: 'CHSnJE7kRhKE-FS6XunxXA',
	sdkSecret: 'bFJ7VOS4GCqEOBK74BypnTRyPIEncvTBfSFu',
	webEndpoint: 'zoom.us',
	topic: 'testing alkurn',
	name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
	password: 'alkurn123',
	signature: '',
	sessionKey: '',
	userIdentity: '',
	// The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
	role: 1,
	hostUrl: `https://www.${host}`,
	apiUrl: `https://api.${host}`,
};
