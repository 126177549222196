import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Home from './feature/home/home';
import Video from './feature/video/video';
import VideoSingle from './feature/video/video-single';
import VideoNonSAB from './feature/video/video-non-sab';
import Preview from './feature/preview/preview';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import LoadingLayer from './component/loading-layer';
import Chat from './feature/chat/chat';
import Command from './feature/command/command';
import Subsession from './feature/subsession/subsession';
import { MediaStream } from './index-types';
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { isAndroidBrowser } from './utils/platform';
import { devConfig } from './config/dev';

import './App.css';

interface AppProps {
	meetingArgs: {
		sdkKey: string;
		topic: string;
		signature: string;
		name: string;
		password?: string;
		webEndpoint?: string;
		enforceGalleryView?: string;
		customerJoinId?: string;
		lang?: string;
	};
	token: any;
	meeting_id: any;
	userIdentity: any;
}

/*
	token: string,
	meeting_id: any,
	userIdentity: any
*/

const mediaShape = {
	audio: {
		encode: false,
		decode: false
	},
	video: {
		encode: false,
		decode: false
	},
	share: {
		encode: false,
		decode: false
	}
};
const mediaReducer = produce((draft, action) => {
	switch (action.type) {
		case 'audio-encode': {
			draft.audio.encode = action.payload;
			break;
		}
		case 'audio-decode': {
			draft.audio.decode = action.payload;
			break;
		}
		case 'video-encode': {
			draft.video.encode = action.payload;
			break;
		}
		case 'video-decode': {
			draft.video.decode = action.payload;
			break;
		}
		case 'share-encode': {
			draft.share.encode = action.payload;
			break;
		}
		case 'share-decode': {
			draft.share.decode = action.payload;
			break;
		}
		case 'reset-media': {
			Object.assign(draft, { ...mediaShape });
			break;
		}
		default:
			break;
	}
}, mediaShape);

declare global {
	interface Window {
		webEndpoint: string | undefined;
		zmClient: any | undefined;
		mediaStream: any | undefined;
		crossOriginIsolated: boolean;
		ltClient: any | undefined;
	}
}

function App(props: AppProps) {
	const { meetingArgs: { sdkKey, topic, signature, name, password, webEndpoint: webEndpointArg, enforceGalleryView, customerJoinId, lang } } = props;

	const [loadingUser, setIsLoadingUser] = useState(true);
	const [loading, setIsLoading] = useState(true);
	const [loadingText, setLoadingText] = useState('');
	const [isFailover, setIsFailover] = useState<boolean>(false);
	const [status, setStatus] = useState<string>('closed');
	const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
	const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
	const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true);
	const zmClient = useContext(ZoomContext);
	let webEndpoint: any;
	if (webEndpointArg) {
		webEndpoint = webEndpointArg;
	} else {
		webEndpoint = window?.webEndpoint ?? 'zoom.us';
	}
	const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
	//const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
	const galleryViewWithoutSAB = false;
	useEffect(() => {
		requestMediaPermissions().then(() => {
			// can successfully access camera and microphone streams
			// DO SOMETHING HERE
			console.log('requestMediaPermissions Success');
		}).catch((err: MediaPermissionsError) => {
			const { type, name, message } = err;
			if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
				console.log('SystemPermissionDenied', type, name, message);
				// browser does not have permission to access camera or microphone
			} else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
				console.log('UserPermissionDenied', type, name, message);
				// user didn't allow app to access camera or microphone
			} else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
				console.log('CouldNotStartVideoSource', type, name, message);
				// camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
				// (mostly Windows specific problem)
			} else {
				console.log('else', type, name, message);
				// not all error types are handled by this library
			}
		});
		const checkMeeting = async () => {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${props.token}`);
			var requestOptions: any = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			const fetchResponse = await fetch(`${devConfig.apiUrl}/user/meeting?item_id=${props.meeting_id}`, requestOptions);
			const response = await fetchResponse.json();

			try {
				let data = response.data;
				let user_id = parseInt(props.userIdentity);
				let meetingId = parseInt(props.meeting_id);
				console.log('response', data)
				if (data.id === meetingId && (data.friend_id === user_id || data.user_id === user_id)) {
					setIsLoadingUser(false);
					console.log('user_id', user_id);
				} else {
					window.location.href = devConfig.hostUrl;
				}
			} catch (error) {
				console.error('error', error)
				window.location.href = devConfig.hostUrl;
			}

		};
		checkMeeting();


		console.log('galleryViewWithoutSAB', galleryViewWithoutSAB)

		const init = async () => {
			await zmClient.init('en-US', `${window.location.origin}/lib`, {
				webEndpoint,
				enforceMultipleVideos: galleryViewWithoutSAB,
				enforceVirtualBackground: galleryViewWithoutSAB,
				stayAwake: true,
				leaveOnPageUnload: true,
			});
			try {
				setLoadingText('Joining the session...');
				await zmClient.join(topic, signature, name, password).catch((e) => {
					console.log(e);
					if (e.reason === 'Meeting Passcode wrong.') {
						alert(e.reason)
					}
				});
				const stream = zmClient.getMediaStream();
				setMediaStream(stream);
				setIsSupportGalleryView(stream.isSupportMultipleVideos());
				setIsLoading(false);
			} catch (e: any) {
				setIsLoading(false);
				message.error(e.reason);
			}
		};
		init();
		return () => {
			ZoomVideo.destroyClient();
		};
	}, [sdkKey, signature, zmClient, topic, name, password, webEndpoint, galleryViewWithoutSAB, customerJoinId]);

	const onConnectionChange = useCallback(
		(payload) => {
			if (payload.state === ConnectionState.Reconnecting) {
				setIsLoading(true);
				setIsFailover(true);
				setStatus('connecting');
				const { reason, subsessionName } = payload;
				if (reason === ReconnectReason.Failover) {
					setLoadingText('Session Disconnected,Try to reconnect');
				} else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
					setLoadingText(`Joining ${subsessionName}...`);
				} else if (reason === ReconnectReason.BackToMainSession) {
					setLoadingText('Returning to Main Session...');
				}
			} else if (payload.state === ConnectionState.Connected) {
				setStatus('connected');
				if (isFailover) {
					setIsLoading(false);
				}
				window.zmClient = zmClient;
				window.mediaStream = zmClient.getMediaStream();

				console.log('getSessionInfo', zmClient.getSessionInfo());
			} else if (payload.state === ConnectionState.Closed) {
				setStatus('closed');
				dispatch({ type: 'reset-media' });
				if (payload.reason === 'ended by host') {
					Modal.warning({
						title: 'Meeting ended',
						content: 'This meeting has been ended by host'
					});
				}
			}
		},
		[isFailover, zmClient]
	);
	const onMediaSDKChange = useCallback((payload) => {
		const { action, type, result } = payload;
		dispatch({ type: `${type}-${action}`, payload: result === 'success' });
	}, []);

	const onDialoutChange = useCallback((payload) => {
		console.log('onDialoutChange', payload);
	}, []);

	const onAudioMerged = useCallback((payload) => {
		console.log('onAudioMerged', payload);
	}, []);

	const onLeaveOrJoinSession = useCallback(async () => {
		if (status === 'closed') {
			setIsLoading(true);
			await zmClient.join(topic, signature, name, password);
			setIsLoading(false);
		} else if (status === 'connected') {
			await zmClient.leave();
			message.warn('You have left the session.');
		}
	}, [zmClient, status, topic, signature, name, password]);

	useEffect(() => {
		zmClient.on('connection-change', onConnectionChange);
		zmClient.on('media-sdk-change', onMediaSDKChange);
		zmClient.on('dialout-state-change', onDialoutChange);
		zmClient.on('merged-audio', onAudioMerged);
		return () => {
			zmClient.off('connection-change', onConnectionChange);
			zmClient.off('media-sdk-change', onMediaSDKChange);
			zmClient.off('dialout-state-change', onDialoutChange);
			zmClient.off('merged-audio', onAudioMerged);
		};
	}, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

	return (
		<div className="App">
			{(loading || loadingUser) && <LoadingLayer content={loadingText} />}
			{!(loading || loadingUser) && (
				<ZoomMediaContext.Provider value={mediaContext}>
					<Router>
						<Switch>
							<Route
								path="/"
								render={(props) => <Home {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />}
								exact
							/>
							<Route path="/index.html" component={Home} exact />
							<Route path="/chat" component={Chat} />
							<Route path="/command" component={Command} />
							<Route
								path="/video"
								component={isSupportGalleryView ? Video : galleryViewWithoutSAB ? VideoNonSAB : VideoSingle}
							/>
							<Route path="/subsession" component={Subsession} />
							<Route path="/preview" component={Preview} />
						</Switch>
					</Router>
				</ZoomMediaContext.Provider>
			)}
		</div>
	);
}

export default App;
